@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  width: 100vw;

  @include lg-min {
    width: 600px;
    height: auto;
  }
}

.header {
  background-color: $bg-grey;
  font-family: $body-font;
  letter-spacing: $letter-spacing-headline;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: $space-xs 0;
}

.button {
  width: calc(50% - $space-xxs);
}

.close {
  cursor: pointer;
}
