@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

// Brand adjustable values
$title-color: $color-black !default;
$title-font-size: $font-size-lg !default;
$title-font-weight: $font-weight-base !default;
$title-font-style: normal !default;
$title-font-family: $heading-font !default;
$title-text-transform: none !default;

.container {
  --width: calc(var(--video-width) * 1px);
  --height: calc(var(--video-height) * 1px);
  --icon-size: 25px;
  --icon-color: #{$color-white};
  position: relative;
  display: flex;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  width: var(--width);
  height: var(--height);
}

.spinner {
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--icon-color);
  position: absolute;
  top: calc(50% - calc(var(--icon-size) / 2));
  left: calc(50% - calc(var(--icon-size) / 2));
}

// Used as a wrapper for the icon elements.
.icons {
  display: flex;
  position: absolute;
  gap: $space-xxxs;
  bottom: 0;
  right: 0;
  padding: $space-sm;
}

// When an icon has multiple elements (eg. when the
// Progress indicator is used, both the indicator and
// the play/pause icon are bundled together), the
// .iconContainer class serves as a wrapper element.
// In those cases, an alternate icon class would
// typically be used for the icon element (eg.
// .iconReducedSize).
body .icon,
body .iconContainer {
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  color: var(--icon-color);
  padding: $space-xxxs;
  box-sizing: content-box;

  svg {
    width: 100%;
    height: 100%;
  }
}

// When using the progress indicator, the play/pause icon
// needs to be reduced in size in order to fit properly
// within the progress ring.
body .iconReducedSize {
  position: relative;
  top: -$space-xxxs;
  left: 1px;
  width: calc(var(--icon-size) * 0.4);
  height: calc(var(--icon-size) * 0.4);
  color: var(--icon-color);
  padding: 7px;
  box-sizing: content-box;
}

// Play icon needs slight shift for proper alignment
// within progress indicator (when used)
.iconShift {
  left: 2px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.title {
  position: absolute;
  top: 0;
  left: 0;
}

.titleText {
  color: $title-color;
  padding: $space-md $space-lg;
  font-size: $title-font-size;
  font-weight: $title-font-weight;
  font-style: $title-font-style;
  font-family: $title-font-family;
  text-transform: $title-text-transform;
}
