@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

// Root container
$root-padding: $space-md 0 !default;
$root-margin: 0 0 $space-md !default;

// Embedded container
$embedded-margin: $space-xxxs $space-xxxs 0 !default;

.root {
  padding: $root-padding;
  margin-bottom: $root-margin;

  @include md-min {
    display: flex;
    justify-content: stretch;
    flex-wrap: nowrap;
    max-width: $page-content-lg-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.itemContainer {
  min-width: 0;

  @include sm-max {
    width: 100%;
  }
}

.embeddedContainer {
  margin: $embedded-margin;
}

.portrait_ratio1x1 {
  flex: 0;
}

.landscape_ratio4x3 {
  @include md-min {
    flex: 0 0 57.1429%;
  }

  + .portrait_ratio1x1 {
    @include md-min {
      flex: 0 0 42.8571%;
    }
  }
}

.landscape_ratio16x9 {
  @include md-min {
    flex: 0 0 64%;
  }

  + .portrait_ratio1x1 {
    @include md-min {
      flex: 0 0 36%;
    }
  }
}
