@use "sass:color";
@use "@/styles/variables" as *;

$icon-size: $font-size-lg;
$close-icon-size: $font-size-base;

.alert {
  box-sizing: border-box;
  margin-bottom: $space-sm;

  border: 1px solid;
  border-radius: 5px;

  max-height: 50vh;

  overflow: hidden;

  transition-property: max-height transform opacity margin-bottom;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;

  &.hidden {
    max-height: 0;
    transform: translateY(-100%);
    opacity: 0;
    margin-bottom: 0;
  }
}

.contentWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  padding: $space-base;
}

.mainContent {
  flex: 1;
}

.iconContainer,
.closeIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.up {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.down {
    justify-content: flex-end;
  }
}

.closeIconContainer {
  margin-left: $space-xs;
  width: $close-icon-size;
}

.closeIcon {
  width: $close-icon-size;
  height: $close-icon-size;
}

.iconContainer {
  margin-right: $space-xs;
  width: $icon-size;
}

.icon {
  width: $icon-size;
  height: $icon-size;
}

// Themes & Variants

@mixin variant-colors($color) {
  &.light {
    $content-color: color.mix($color-black, $color, 35%);

    background-color: color.mix($color, $color-white, 25%);
    color: $content-color;
    border-color: rgba($content-color, 0.1);
  }

  &.dark {
    $content-color: color.mix($color-white, $color, 75%);

    background-color: color.mix($color, $color-black, 65%);
    color: $content-color;
    border-color: rgba($content-color, 0.1);
  }
}

.primary {
  @include variant-colors($color-primary);
}

.warning {
  @include variant-colors($color-warning-500);
}

.error {
  @include variant-colors($color-danger-500);
}
