@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.buttonContainerStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;

    @include sm-min {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.textBlock {
  width: 100%;

  @include md-min {
    width: 40%;
    order: 1;
  }
}

.mainImage {
  width: 100%;

  @include md-min {
    width: 60%;
  }
}
