@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

$text-color: $color-white !default;

.container {
  color: $text-color;
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-small-text;
}
