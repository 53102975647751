@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@keyframes show-links {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.footerBlock {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.blockHeader {
  font-size: $font-size-xs;
  text-transform: uppercase;
  padding-left: $space-sm;
  font-weight: $font-weight-bold;
  margin: $space-sm 0;
  letter-spacing: $letter-spacing-small-text;
  font-family: $body-font;
}

.accordionHeader {
  padding-left: 0;
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0;
  animation: show-links linear 450ms;
  margin: 0;

  // The margin on the header is necessary to remain the same to keep the accordion button
  // the same size, but we need to move the links closer to the header, so negative margin it is
  margin-top: -$space-xxs;
  margin-bottom: $space-xxs;
}

.item {
  list-style: none;
  display: flex;
}

body .link {
  font-size: $font-size-base;
  line-height: $line-height-relaxed;
  padding: $space-xxxs $space-sm;
  color: $color-navigation-foreground;
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }
}

.accordion {
  --rotation: 180deg; // Used by icon animations

  display: block;
  width: 100%;
  padding: 0 $space-sm;

  & > div {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
  }

  a {
    padding-left: 0;
  }
}
