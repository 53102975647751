@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$default-icon-size: 12px;

.listContainer {
  .socials__list {
    // resetting the margin/padding/line-height to avoid extra spacing
    margin: 0;
    padding: 0;
    line-height: $line-height-none;
  }
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: $font-size-xl;
}

.item {
  margin-right: $space-xxxs;
}

.link {
  padding: $space-xxs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-primary;
  text-decoration: none;

  &:hover {
    color: $color-dark;
  }
}

.blog,
.facebook,
.instagram,
.pinterest,
.twitter,
.youtube {
  width: $default-icon-size;
}
