@use "@/styles/variables" as *;

.headerWrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $header-layer;
  width: 100%;
}