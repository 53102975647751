@use "@/styles/variables" as *;

.container {
  --size: 25px;
  position: absolute;
  left: $space-xxxs;
  top: $space-xxxs;
  width: var(--size);
  height: var(--size);
  color: $color-white;
}

.progress {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg) scaleY(-1);
}
