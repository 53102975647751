@use "@/styles/variables" as *;
@use "../styles.base.module" as *;

.searchWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: 300ms;
  opacity: 0;
  display: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  z-index: $search-layer;

  & > form {
    height: 100%;
  }
}

.inlineSearchWrapper {
  // position: absolute;
  // right: 80px;
  width: 150px;
  overflow: hidden;
  transition: 300ms;
  opacity: 0;
  display: none;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  z-index: $search-layer;
}

.enterActive {
  display: flex;
}

.enterDone {
  display: flex;
  opacity: 1;
  height: $header-height;
}

.inlineEnterDone {
  display: flex;
  opacity: 1;
}

.exitActive {
  opacity: 0;
  height: 0;
}
