@use '@/styles/variables/colors' as *;

.slideContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

:is(body) {
  .variantHomepagePaginationButton {
    background-color: rgb(255 255 255 / 50%);
    border: 1px solid $color-lighter-gray;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    margin: 5px;
    transition: 300ms;
  }

  .variantHomepagePaginationButtonActive {
    background-color: $color-lighter-gray;
    border-color: rgb(255 255 255);
  }

  .fullWidthSlider {
    width: 100%;
    height: 33vw !important;
  }
}
