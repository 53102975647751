.wrapper {
    position: relative;
}

.link {
    margin: 20px;
    position: absolute;
}

.top {
    top: 0;
}

.bottom {
    bottom: 0;
}

.right {
    right: 0;
}

.left {
    left: 0;
}
