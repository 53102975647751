@use "@/styles/variables" as *;

.termsAndConditions {
  margin: $space-md 0 18px;
}

.link {
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
}
