@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.container {
  margin-bottom: $space-md;
}

.topSection {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include md-min {
    flex-direction: row;
  }
}

.tileBlock {
  display: flex;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;

  @include md-min {
    flex-direction: column;
    width: 40%;
  }
}

.imageBlock {
  width: 100%;
  order: -1;

  @include md-min {
    width: 60%;
    order: 0;
  }
}
