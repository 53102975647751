@use "@/styles/variables" as *;

.select {
  border: 1px solid $color-gray-2;
  height: 36px;
  min-width: 86px;
  color: $color-black;

  appearance: none;
  text-indent: 1px;
  padding-left: 9px;
  padding-right: 20px;

  text-overflow: ellipsis;

  &:focus {
    border: 1px solid $color-primary;
  }

  option {
    width: calc(100% - 20px);
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .selectContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 10px;
  }

  label {
    width: 100%;
    margin-bottom: $space-xxs;
    text-transform: capitalize;
  }

  &.error {
    .select {
      border: 1px solid $color-error;
    }

    span {
      color: $color-error;
    }
    min-width: 86px;

    appearance: none;
    text-indent: 1px;
  }
}

.fullWidth {
  width: 100%;
}

.errorMessage {
  width: 100%;
}

.icon {
  height: 100%;
  display: flex;
  position: absolute;
  right: 10px;
  justify-content: center;
  pointer-events: none;
}
