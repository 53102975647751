@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  gap: $space-md;
  margin-inline: calc($space-md * 2.5);
  margin-block: calc($space-md);
  flex-wrap: wrap;
  display: flex;
    justify-content: center;
}

.teaser {
  display: flex;
    flex-direction: column;

  @include md-min {
    width: calc(calc(100% / var(--num-cols, 3)) - $space-md);
  }
}

.container {
  @include wrapper;

    @include lg-min {
       margin-inline: auto;
    }
}

.paginationButton {
  @include pagination-button;
}

.paginationButtonActive {
    @include pagination-button-active;
}

.navigationButton {
  @include navigation-button;
}
