@use "@/styles/variables" as *;

// TODO: Break this file out once reasonable patterns begin to emerge.
.colorError {
  color: $color-error;
}

.fontWeightBold {
  font-weight: $font-weight-bold;
}

.lineHeightRelaxed {
  line-height: $line-height-relaxed;
}

.fontSizeSm {
  font-size: $font-size-xs;
}
