@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.cartContainer {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 $space-xs $space-xxxs;
  width: 100%;
  gap: $space-sm;
  margin-bottom: $space-sm;

  @include md-min {
    flex-direction: row;
    justify-content: center;
    padding: $space-lg;
  }
}

.cartHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: $font-weight-bold;
  text-align: center;
  margin: $space-xs 0;
  font-size: $font-size-sm;

  h3 {
    letter-spacing: $letter-spacing-headline;
    font-size: $font-size-sm;
    font-weight: $font-weight-base;
    margin: $space-xxxs;
  }

  @include md-min {
    display: none;
  }
}

.emptyCart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;

  @include md-min {
    margin: 100px 0;
  }
}

.loginCTA {
  display: contents;

  &.hide {
    visibility: hidden;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $space-md;
}

.productLineList {
  max-width: 900px;
  order: 2;

  @include md-min {
    width: calc(75% - ($space-sm * 2));
    margin: 0 $space-sm 0 0;
    order: 1;
  }
}

.orderSummary {
  width: 100%;
  order: 1;

  @include md-min {
    width: 25%;
    order: 2;
  }
}

.cartFooter {
  padding: 20px $space-sm;
  background-color: $color-white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 -2px 10px #ccc;
  transition: transform 0.3s;

  &.hidden {
    pointer-events: none;
    transform: translateY(100%);
    visibility: hidden;
  }
}

.paypalBtn {
  background-color: $color-paypal-button;
  border-color: $color-paypal-button;
  color: $color-black-gray;
  margin-top: $space-xs;
}

:is(body) {
  .editModal {
    width: 100vw;
    max-height: 100vh;
    overflow-y: scroll;

    @include xs-max {
      width: 100vw;
    }

    @include sm-min {
      width: min(900px, 95vw);
      height: auto;
    }
  }

  .modalHeader {
    background-color: $color-gray-light;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-headline;
    text-transform: uppercase;
    position: sticky;
    top: 0;
  }
}

.modalBody {
  padding: $space-sm;
}

.content {
  margin: $space-md 0;
}
