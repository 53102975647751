@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/component/modal.scss" as *;
@use "@/styles/utils" as *;

.container {
  position: absolute;
}

.icon {
  color: $color-primary;
}

.label {
  color: $color-black;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  letter-spacing: 0;
}

.icon,
.label {
  @include transition($transition-duration, $transition-timing-function, color);
}

.content {
  display: grid;
  place-items: center;
}

:is(body) {
  .right {
    right: 20px;
  }

  .left {
    left: 20px;
  }

  .button {
    display: flex;
    gap: 0.1rem;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    min-width: $space-xl;
    width: $space-xl;
    min-height: $space-xl;
    justify-content: center;
    box-shadow: 0.1em 0.1em 1em rgba(0, 0, 0, 0.2),
      -0.1em -0.1em 1em rgba(0, 0, 0, 0.2);
    align-items: center;
    cursor: pointer;

    @include transition(
      $transition-duration,
      $transition-timing-function,
      background-color,
      opacity,
      visibility
    );

    &:hover :where(.icon, .label) {
      color: $color-white;
    }
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }
}
