@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.wrapper {
  width: 100%;
}

:is(body) {
  // Button for controlling banner swiper.
  .pagination {
    background-color: rgb(255 255 255 / 50%);
    border: 1px solid $color-lighter-gray;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    margin: 5px;
    transition: 300ms;
  }

  .paginationActive {
    background-color: $color-lighter-gray;
    border-color: rgb(255 255 255);
  }

  .navigation {
    @include circle;
    @include button-transition;

    border: 1px solid $carousel-navigation;
    color: $carousel-navigation;
    background: $carousel-navigation-background;

    svg {
      transform: scale(0.7);
      width: 70px;
    }

    @media (hover: hover) {
      &:hover {
        border: 1px solid $color-black;
        color: $color-black;
      }
    }
  }
}
