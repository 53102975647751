@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: $space-xs 20px;
  background-color: $color-white-gray;

  h1 {
    margin: 0;
    font-family: $body-font;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-headline;
  }

  button {
    font-weight: $font-weight-bold;
  }
}
