@use "@/styles/variables" as *;
@use "@/styles/helpers/helpers.scss" as *;
@use "@/styles/utils" as *;

.content {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: $space-sm;
  padding-left: 0;
}

.list {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @include phone {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
    width: 100%;
  }

  @include md-max {
    padding-right: $space-sm;
  }
}

.item {
  display: flex;
  padding: 0 $space-sm;
  border-right: 1px solid rgba(0 0 0 / 20%);

  &:last-child {
    border-right: none;
  }

  @include phone {
    border-right: 0;
    padding-top: $space-xxxs;
    padding-bottom: $space-xxxs;
  }

  @include md-max {
    margin-bottom: $space-xs;
  }
}

body .link {
  font-size: $font-size-xs;
  color: $color-navigation-foreground;
  line-height: $line-height-relaxed;

  &:hover {
    text-decoration: underline;
  }
}

.accessibilityLink {
  display: flex;
  align-items: center;

  & > a {
    display: flex;
  }

  @include md-max {
    margin-left: auto;
  }
}

.accessibilityImage {
  height: 28px;
}

.flag {
  display: inline-flex;
  margin-right: 10px;
}
