@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

$scroll-distance: 700vh !default;
$module-content-width: $content-wrapper-max-width !default;
$module-header-height: 45px !default;
$module-header-background: $color-white !default;
$module-title-color: $color-shading-600 !default;
$module-title-offset-color: $color-black !default;
$info-background: $color-shading-600 !default;
$heading-color: $color-white !default;
$heading-offset-color: $color-black !default;
$sub-heading-color: $color-white !default;
$content-background: $color-shading-600 !default;

.container {
  height: $scroll-distance;
}

.wrapper {
  position: sticky;
  top: 0;
  overflow: hidden;
  height: 100vh;
}

.header {
  height: $module-header-height;
  display: flex;
  align-items: center;
  background: $module-header-background;
  padding: 4px 30px;
  border-top: 1px solid $color-black;
}

.title {
  font-size: $font-size-base;
  font-weight: $font-weight-thin;
  color: $module-title-color;
  margin: auto;
  width: 100%;
  max-width: $module-content-width;

  @include md-min {
    font-size: $font-size-lg;
  }
}

.title span {
  color: $module-title-offset-color;
}

.infoContainer {
  height: $module-header-height;
  display: flex;
  align-items: center;
  background: $info-background;
  padding: 4px 30px;
  border-bottom: 1px solid $color-white;
}

.infoWrap {
  margin: auto;
  width: 100%;
  max-width: $module-content-width;
  display: flex;
  justify-content: flex-end;
}

.contentContainer {
  background: $content-background;
  padding: 30px 30px 50px;
  border-top: 1px solid $color-black;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @include xs-max {
    padding: 30px 30px 105px;
  }

  // Fix for mobile landscape overlap
  @media screen and (max-height: 480px) {
    padding: 30px 30px 25px;
  }
}

.contentWrap {
  margin: auto;
  width: 100%;
  max-width: $module-content-width;

  @include md-min {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    column-gap: $space-xxl;
  }

  @include lg-min {
    column-gap: $space-xxxl;
  }

  // Fix for mobile landscape overlap
  @media screen and (max-height: 480px) {
    display: flex;
    flex-wrap: nowrap;

    > * {
      flex-shrink: 1 !important;
    }

    .heading {
      font-size: $font-size-lg;
    }

    .subHeading {
      font-size: $font-size-xs;
      padding-right: 20px;
    }

    .cta {
      flex-basis: 30% !important;
    }
  }
}

.heading {
  display: inline-block;
  font-size: calc(#{$font-size-xxl} - 1px);
  font-weight: $font-weight-thin;
  color: $heading-color;
  margin: 0 0 0.5em;
  max-width: 320px;
  padding-right: 20px;
  flex-shrink: 0;
  flex-grow: 0;

  @include md-min {
    font-size: $font-size-xxl;
    margin: 0 0 0.75em;
  }
}

.heading span {
  color: $heading-offset-color;
}

.subHeading {
  display: inline-block;
  font-size: $font-size-base;
  font-weight: $font-weight-thin;
  margin: 0.1em 0 1em;
  color: $sub-heading-color;
  max-width: 220px;
  letter-spacing: $letter-spacing-small-text;
  flex-shrink: 0;
  flex-grow: 0;

  @include lg-min {
    max-width: 330px;
  }
}

.cta {
  position: relative;
  flex-basis: 100%;
  z-index: 1;

  @include lg-min {
    flex-basis: auto;
  }
}

.link {
  display: inline-block;
}

.button {
  min-width: 100px;
}

.canvasWrap,
.imageWrap {
  position: absolute;
  top: calc(50% + #{$module-header-height} * 1.5 - 175px);
  left: 50%;
  width: 100%;
  max-width: calc(#{$module-content-width} + 200px);
  transition: left 0.5s ease-in-out;

  /* Subtract out header and (approx) footer heights */
  height: calc(100vh - #{$module-header-height} - 180px);

  @include sm-min {
    top: calc(50% + #{$module-header-height} * 1.5 - 80px);
  }
}

/* Shoe 1 / canvas start position */
.canvasWrap {
  transform: translate(-50%, -50%);
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
}

/* Additional shoe start position */
.imageWrap {
  transform: translate(-200%, -50%);
}

.imageWrap img {
  width: 100%;
}
