@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.posStoreBanner {
  background-color: $color-exchange-ui-bg;
  color: $color-white;

  font-family: $body-font;
  font-size: $font-size-sm;
  line-height: $line-height-relaxed-sm;
  font-weight: $font-weight-base;

  display: flex;
  flex-direction: row;
}
