@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.grid {
  --grid-columns: var(--grid-columns-mobile, 2);
  --grid-gap: clamp(#{$space-xs}, 2.5vw, #{$space-md});
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-gap: var(--grid-gap);
  margin-block: var(--grid-gap);
  margin-inline: auto;
  padding-inline: var(--grid-gap);
  justify-content: center;
  height: fit-content;
  border: solid transparent;
  max-width: 1200px;
  overflow: hidden;

  @include tablet {
    --grid-columns: var(--grid-columns-tablet, 3);
  }

  @include desktop {
    --grid-columns: var(--grid-columns-desktop, 3);
  }
}

.item {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color ease-in-out 200ms;

  & > h3 {
    margin-block: min(var(--grid-gap), 1em);
    line-height: $line-height-relaxed;
    font-family: $body-font;
    font-size: clamp(#{$font-size-sm}, 2vw, #{$font-size-md});
    font-weight: $font-weight-bold;
    color: $color-white;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.item:where(:hover):not(.badge) > .overlay {
  background-color: $color-primary;
}

// where there is a sold out class, place a "sold out" message in upper-right hand corner.
.badge::after {
  --border-width: 75px;
  content: "";
  border-left: var(--border-width) solid transparent;
  border-right: var(--border-width) solid transparent;
  border-bottom: var(--border-width) solid $color-primary;
  position: absolute;
  rotate: 45deg;
  transform-origin: 50% top;
  right: calc(var(--border-width) * -1);
  top: 0;
}

.badge::before {
  --border-width: 75px;
  --margin: #{$space-xs};
  z-index: 1;
  content: var(--content);
  position: absolute;
  width: fit-content;
  margin: var(--margin);
  color: $color-white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: $font-weight-bold;
  right: 0;
  top: 0;
  max-width: calc(calc(var(--border-width) / 2) + var(--margin));
  text-align: right;
}
