@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$custom-space: 18px;

.container {
  background-color: $color-error-light;
  color: $color-error-dark;
  padding: $space-xs 0 $space-xs $space-xs;

  @include md-min {
    font-size: $font-size-lg;
    padding: $custom-space 0 $custom-space $custom-space;
  }
}

.error {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.errorMessage {
  display: flex;
  align-items: center;
}

.message {
  font-family: $heading-font;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-headline;
  margin: $space-xxxs $space-xxs 0;

  @include md-min {
    font-size: $font-size-md;
  }
}

.alertIcon {
  width: 20;
  height: 20;
}

.stackTraceBtn {
  margin-left: 26px;

  button {
    font-size: $font-size-xs;
  }
}

.stackTraceWrapper {
  background-color: $color-white;
  border-radius: $space-xxs;
}

.stackTrace {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
  padding: $space-xs;
  font-size: $font-size-xs;

  @include md-min {
    font-size: $font-size-base;
  }
}

.closeStack {
  max-height: 0;
  opacity: 0;
  transition: all ease 0.3s;
  visibility: hidden;
}

.openStack {
  opacity: 1;
  margin: $space-xs $space-xs 0 0;
  max-height: 400px;
  height: 100%;
  overflow-y: scroll;
  transition: all ease 0.3s;

  @include md-min {
    margin: $custom-space $custom-space 0 0;
  }
}

.close {
  text-decoration: none;
  margin-right: $space-xxs;
}
