@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$bg-gray: $color-white-gray;
$bg-border: $color-lighter-gray;

.title {
  text-transform: uppercase;
  font-family: $heading-font;
  font-size: $font-size-base;
  letter-spacing: $letter-spacing-headline;
  margin-bottom: $space-xs;
}

.fieldGroup {
  display: flex;

  @include md-max {
    width: 100%;

    & > input {
      flex: 1;
    }
  }
}

.container {
  display: flex;
}

.emailLabel {
  @include visually-hidden;
}

.emailInput,
.emailError {
  background-color: $bg-grey;
  border: 1px solid $bg-border;
  border-right: 0;
  outline: none;
  transition: 0.5s ease-in-out;
  padding: 0 10px;
  font-size: $font-size-base;
  font-family: $body-font;
  min-width: 200px;
  border-radius: 2px 0 0 2px;

  &::placeholder {
    color: $color-font-base;
  }
}

.emailError {
  border-color: $color-error;
}

.errorMessage {
  color: $color-error;
  font-size: $font-size-xs;
  padding-top: 5px;
}

.joinButton {
  height: 40px !important;
  padding: 0 $space-md;
  border-radius: 0 2px 2px 0;
}

.spinner {
  width: 14px;
  height: 14px;
}
