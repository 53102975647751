@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.applePayBtn {
  margin-top: $space-xxs;
}

:is(body) {
  .editModal {
    max-width: unset;

    @include sm-min {
      width: min(1200px, 95vw);
    }
  }
}