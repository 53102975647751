@use "@/styles/variables" as *;

@keyframes show-drawer-from-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes show-drawer-from-left {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes hide-drawer-to-right {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes hide-drawer-to-left {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes hide-curtain-keyframes {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.root {
  height: 100vh;
  width: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
}

.drawer {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 50%;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  transition: transform 300ms ease-in-out;
  right: 0;
  transform: translate3d(100%, 0, 0);
  animation: show-drawer-from-right 300ms ease forwards;

  &[data-draw-from="left"] {
    right: auto;
    left: 0;
    animation: show-drawer-from-left 300ms ease forwards;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

.exiting {
  animation: hide-drawer-to-right 300ms ease forwards;

  &[data-draw-from="left"] {
    animation: hide-drawer-to-left 300ms ease forwards;
  }
}

.curtainExiting {
  animation: hide-curtain-keyframes 300ms ease forwards;
}

.close {
  pointer-events: none;
}
