@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: $space-xs;

  @include lg-max {
    gap: $space-xxxs;
  }
}

.teaser {
  flex: 1;
  position: relative;
}

.content {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  color: rgb(255 255 255);
  font-size: $font-size-xxxxxl;
  margin: $space-xs 0;
  text-align: center;
  line-height: $line-height-relaxed;
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;

  @include phone {
    font-size: $font-size-xxl;
  }

  p {
    margin: 0;
  }
}

.paginationButton {
  background-color: rgb(255 255 255 / 50%);
  border: 1px solid $color-lighter-gray;
  border-radius: 5px;
  height: 10px;
  width: 10px;
  margin: 5px;
  transition: 300ms;
}

.paginationButtonActive {
  background-color: rgb(0 0 0);
  border-color: 1px solid $color-lighter-gray;
}
