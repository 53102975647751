@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

/* Navigation arrows */
$navigation-width: auto !default;
$navigation-height: auto !default;
$navigation-border: 1px solid $carousel-navigation !default;
$navigation-color: $carousel-navigation !default;
$navigation-background: $carousel-navigation-background !default;
$navigation-transition: all 1s !default;
$navigation-hover-border: 1px solid $color-black !default;
$navigation-hover-color: $color-black !default;
$navigation-hover-background: inherit !default;

/* Pagination indicators */
$indicator-width: 8px !default;
$indicator-height: 8px !default;
$indicator-outline: 2px solid $carousel-navigation !default;
$indicator-outline-offset: 1px !default;
$indicator-background-color: $color-light !default;
$indicator-margin: $space-xxs !default;
$indicator-inactive-opacity: 0.5 !default;
$indicator-active-outline: 2px solid $carousel-navigation !default;
$indicator-active-background-color: $carousel-navigation !default;

.root {
  position: relative;

  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 0 auto;
}

.container {
  position: sticky;
  top: 0;
}

:is(body) {
  .paginationWrapper {
    position: absolute;
    left: -12px !important;
    bottom: 12px !important;
    transform: none !important;
    padding: 10px 32px !important;
    display: flex;

    @include xs-max {
      left: -16px !important;
      display: block;
    }
  }

  .bullet {
    width: $indicator-width;
    height: $indicator-height;
    outline: $indicator-outline;
    outline-offset: $indicator-outline-offset;
    background-color: $indicator-background-color;
    margin: $indicator-margin;
    opacity: $indicator-inactive-opacity;

    @include xs-max {
      margin: $space-xxs;
      display: block;
    }
  }

  .activeBullet {
    outline: $indicator-active-outline;
    background-color: $indicator-active-background-color;
  }
}