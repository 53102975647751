@use "@/styles/variables" as *;

$card-padding-md: 10px;

.rootCommon {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    height: 40px;
    padding: 0 $card-padding-md; // vertical padding is set to 0, as vertical alignment is handled by flexbox.

    .titleText {
      text-transform: uppercase;
      font-size: $font-size-base;
      letter-spacing: $letter-spacing-headline;
      font-weight: $font-weight-bold;
    }
  }
}

// border = true
.rootBorder {
  border: 1px solid $color-border-light;
}

// variant = 'active'
.rootActive {
  .title {
    color: $color-light;
    background-color: #333333;
  }
}

// variant = 'default'
.rootDefault {
  .title {
    color: $color-font-base;
    background-color: $color-white-gray;
  }
}

// padding = 'md'
.rootPaddingMd {
  .container {
    padding: $card-padding-md;
  }
}
