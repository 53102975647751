@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

@forward "./styles.base.module.scss" with (
  $title-font-size: $font-size-md,
  $title-font-weight: $font-weight-xthin,
  $title-font-style: italic,
  $title-text-transform: uppercase,
);

.overlay {
  background-color: transparent;
}
