@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.content {
  padding-left: $space-sm;
}

body .link {
  font-size: $font-size-xxs;
  font-weight: $font-weight-regular;
  letter-spacing: $letter-spacing-small-text;
  color: $color-black;
  transition: color 0.15s ease-out;

  &:hover {
    color: $color-primary-300;
  }
}
