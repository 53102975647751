@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;
@use "@/styles/utils" as *;

.details {
  display: block;

  .summary::marker,
  .summary::-webkit-details-marker {
    content: "";
    display: none;
  }
}

.button::after {
  content: var(--open, '+');
  margin-bottom: $space-sm;
  display: block;
  text-decoration: underline;
  cursor: pointer;
  order: 1;
}

// Select the first sibling button following an open details element.
.details[open] + .button::after {
  content: var(--close, '-');
}

.details[open] {
  position: relative;

  .summary {
    display: contents;
  }

  .summary::marker,
  .summary::-webkit-details-marker {
    content: "";
    display: none;
  }
}

// Position classes.
.center {
  text-align: center;
  margin-inline: auto;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $space-md $space-lg;

  @include md-min {
    flex-direction: column;
    justify-content: space-between;
  }
}

.button-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-block: $space-md;

    @include md-min {
        display: contents;
    }
}
