@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss" with (
  $indicator-outline: 1px solid $color-light,
  $indicator-outline-offset: 0,
  $indicator-background-color: transparent,
  $indicator-margin: $space-xxxs,
  $indicator-inactive-opacity: 1,
  $indicator-active-outline: 1px solid $color-light,
  $indicator-active-background-color: $color-darker-gray,
);

:is(body) {
  .paginationWrapper {
    @include xs-max {
      // Allow for space for iOS address bar
      bottom: $space-xxxl !important;
    }
  }
}