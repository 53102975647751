@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  isolation: isolate;

  @include wrapper;
  max-width: var(--max-width, $wrapper-max-width);

  @include lg-min {
    margin-inline: auto;
  }
}

.teaser {
  position: relative;
  z-index: 2;
  padding: $space-md;
}

.first {
  position: absolute;
  width: 100%;
}

.right {
  margin-left: auto;
}

.left {
  margin-right: auto;
}

.center {
  margin-left: auto;
  margin-right: auto;
}
