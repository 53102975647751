@use "@/styles/variables" as *;
@use "@/styles/utils" as *;


.bottomLink {
  padding: $space-xxl 0 0 $space-xxl;

  @include md-max {
    display: none;
  }
}
