@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.priceValue {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    letter-spacing: $letter-spacing-headline;
    margin-top: $space-xxs;
}

.priceStrikethrough {
    font-weight: normal;
    text-decoration: line-through;
    opacity: 0.75;
}
