@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.columnLeft {
  position: relative;
  left: -$space-xxs;  // Compensate for icon margins
  top: $space-xxxs;
}

.columnRight {
  position: relative;
  right: -$space-xxs;  // Compensate for icon margins
  top: $space-xxxxs;
}
