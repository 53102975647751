@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.nav {
  background: $color-primary;
}

.navItem {
  border-bottom: none;
  margin-bottom: $space-xs;

  .navLink {
    text-transform: none;
    font-weight: $font-weight-thin;
    font-size: $font-size-xxxl;
    padding: 0 $space-md;
    line-height: 1;

    span {
      font-size: $font-size-sm;
    }

    @include xs-max {
      font-size: $font-size-xxl;
    }

    &.navLinkHeader {
      font-weight: $font-weight-thin;
    }
  }
}

.parentDrawer,
.innerDrawer {
  background-color: $color-navigation-mobile-background;
}

.back {
  padding: $space-xxl $space-md $space-lg;
  font-weight: $font-weight-thin;
  font-size: 22px;
  background: $color-primary;
  border-bottom: none;
  width: auto !important;
  display: inline-block;
  align-self: flex-start;
}
