@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

@forward "./styles.base.module.scss";

.wrapper {
    width: 100%;
    justify-content: flex-start;
    background: transparent;
}

:is(body) {
    .pagination {
        margin-bottom: $space-sm;
        width: 100%;
        border: none;
        background: transparent;
        margin-inline: 0;
    }

    .navigation {
        @include circle;
        @include button-transition;
        width: 30px;
        height: 30px;
        border: none;
        color: $color-light;
        background-color: $color-black;
    }

    @media (hover: hover) {
        .navigation {
            &:hover {
                border: none;
                color: $color-black;
                background-color: $color-light;
            }
        }
    }

    .circle {
        width: 10px;
        height: 10px;
        outline: none;
        outline-offset: 0;
        background-color: $color-secondary-500;
        margin: $space-xxxs;
    }

    .activeCircle {
        background-color: $color-black;
    }
}
