@forward "./styles.base.module.scss";

.button {
    background-color: rgba(255, 255, 255, 0.7);
    text-transform: unset;
}

.button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}
