@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.teaser {
    position: relative;
    z-index: 2;
}

.first {
  position: absolute;
  width: 100%;
}
