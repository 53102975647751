@use "@/styles/variables" as *;

.address {
    display: flex;
    flex-direction: column;
}

.line {
    & + & {
        margin-top: $space-xxxs;
    }
}
