@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.wrapper {
  margin-bottom: 20px;
  max-width: 340px;
  min-width: 250px;
}

.needHelp {
  width: fit-content;

  &.fullWidth {
    width: 100%;
  }
}

.top {
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: $color-gray-1;
  font-weight: $font-weight-bold;
  font-size: 15px;
}

.chatButton {
  font-size: inherit;
  font-weight: inherit;
  text-decoration: underline;
}

.cardHeader {
  padding: 16px 12px;
  font-family: $body-font;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  @include md-min {
    padding: $space-xs $space-base;
  }
}

.listContainer {
  width: 100%;
  height: fit-content;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;

  @include md-min {
    padding: $space-sm $space-base;
  }
}

.link {
  width: 100%;
  flex: 1;

  font-size: $font-size-sm;
  line-height: 1.5;
  text-transform: none;
}

.title {
  font-weight: $font-weight-bold;
}

.fullWidth {
  max-width: 100%;
}
