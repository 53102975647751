@use "@/styles/variables" as *;

.slideContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.headlines {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: $line-height-relaxed;

  h2 {
    margin: 0;
    font-weight: $font-weight-regular;
    margin-top: $space-md;
    font-size: $font-size-xs;
    letter-spacing: $letter-spacing-headline;
  }

  p {
    margin: 0;
    font-family: $body-font;
    font-size: $font-size-md;
    font-weight: $font-weight-bold;
  }
}

:is(body) {
  .headline {
    margin-bottom: 0;
    width: 100%;

    h3 {
      margin-bottom: $space-xs;
    }
  }

  .paginationButton {
    background-color: rgb(255 255 255 / 50%);
    border: 1px solid $color-lighter-gray;
    border-radius: 5px;
    height: 10px;
    width: 10px;
    margin: 5px;
    transition: 300ms;
  }

  .paginationButtonActive {
    background-color: $color-lighter-gray;
    border-color: rgb(255 255 255);
  }
}
