@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.logo {
  display: flex;
  align-items: center;
  color: $color-header-logo;
  height: $header-height - 10px;
  margin-right: $space-sm;

  & > svg {
    height: 100%;
  }

  @include md-max {
    margin: 0;
  }
}
