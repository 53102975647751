@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: $space-xs;

  span {
    font-size: $font-size-sm;
    margin-bottom: $space-xxxs;
    white-space: nowrap;

    &.inStock {
      color: $color-primary-dark;
      font-weight: $font-weight-bold;
      margin-top: $space-xxxs;
    }
  }

  button {
    font-weight: $font-weight-bold;
    text-decoration: underline;
  }
}

.sizeLoader {
  margin-bottom: $space-xxxs;
  border-radius: 5px;
  width: 100px;
  height: 18px;
}

.colorLoader {
  margin-bottom: $space-xxxs;
  border-radius: 5px;
  width: 140px;
  height: 18px;
}
