@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.columnLeft {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.columnRight {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
