@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$field-top-margin: 7px;

.input {
  margin-bottom: $field-top-margin;
}

.requiredLabel {
  display: block;
  margin-bottom: $space-md;
  font-size: $font-size-sm;
}
