@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.successMessage {
  color: $color-primary;
  font-size: $font-size-xs;
  padding-top: 5px;
}

.form {
  margin-bottom: $space-lg;

  @include md-max {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
