.container {
    display: flex;
    flex-direction: row;
}

.item {
    display: flex;
    width: 100%;
}

.item > div:first-child {
   width: 100%;
   flex: 100%;
}