@use "@/styles/variables" as *;
@use "../../styles.base.module" as *;

@mixin navigation-button {
  cursor: pointer;
  appearance: none;
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: $color-navigation-foreground;
  text-decoration: none;
  padding: $space-sm;
  font-weight: $font-weight-bold;
  font-family: $heading-font;
  letter-spacing: $letter-spacing-headline;
  text-transform: uppercase;
  font-size: $font-size-sm;
}

/* The navbar styling (Navigation links) */
.nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navItem {
  display: flex;
  position: relative;
  border-bottom: 1px solid rgb(0 0 0 / 10%);

  // The second level of mobile nav must differ navLink
  .navLink {
    font-family: $body-font;
    font-weight: $font-weight-regular;
    text-transform: uppercase;

    &.navLinkHeader {
      font-family: $heading-font;
      font-weight: $font-weight-bold;
      letter-spacing: $letter-spacing-headline;
    }
  }
}

.navIcon {
  margin-left: auto;
}

.parentDrawer {
  // Taken from the sanuk.com production website
  width: 80%;
  min-width: 264px;
  max-width: 355px;
}

.parentDrawer,
.innerDrawer {
  background-color: $color-navigation-mobile-background;
  height: 100%;
}

.accountNavTitle {
  font-weight: $font-weight-bold;
}

.innerDrawerRoot {
  width: 100%;
}

.innerDrawerCurtain {
  position: absolute;
  width: 100%;
}

.innerDrawer {
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.back {
  @include navigation-button;

  font-weight: $font-weight-bold;
  color: $color-navigation-foreground;
  background: $color-shading-200;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.backIcon {
  display: flex;
  align-items: center;
}

.backText {
  margin-left: 10px;
}

body .navLink {
  @include navigation-button;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-xs;
}

.utilityLink {
  text-transform: uppercase;
  text-decoration: none;
  color: $color-navigation-foreground;
}
