@use "@/styles/variables" as *;

$checkbox-size: 14px;

.container {
  input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;

    width: $checkbox-size;
    height: $checkbox-size;
    border: $space-xxxxs solid $color-primary;

    display: grid;
    place-content: center;

    &::before {
      content: "";
      width: $checkbox-size;
      height: $checkbox-size;
      margin-left: 1px;

      // This clip-path is responsible for create the check mark.
      clip-path: polygon(17% 54%, 28% 43%, 38% 54%, 70% 22%, 81% 33%, 38% 75%, 17% 54%);

      // Transform is responsible for hidden/show the check mark.
      transform: scale(0);
      transform-origin: bottom left;
      box-shadow: inset $checkbox-size $checkbox-size $color-primary;
    }

    &:checked::before {
      transform: scale(1);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  label {
    margin-left: $space-xxs;
    font-family: $body-font;
    font-size: $font-size-sm;
  }
}

.inputContainer {
  display: flex;
}

.error {
  .input {
    border: 1px solid $color-error;
  }

  .errorMessage {
    line-height: 1.25;
    color: $color-error;
    margin-top: $space-xxs;
  }
}
