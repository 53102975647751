@use "@/styles/variables" as *;
@use "@/styles/helpers/overlayStyles" as *;

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: $disabled-layer;
  background-position: center;
  background-size: cover;
}

.responsive {
  display: flex;
  width: 100%;
}

.loading {
  background: linear-gradient(to right, $color-white, $color-white-gray, $color-white-gray, $color-white);
  background-size: 300% 100%;
  animation: gradient linear 3s infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 300% 0%;
  }
}
