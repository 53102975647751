@use "@/styles/utils" as *;

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main {
  flex: 1;
}
