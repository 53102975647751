@use "@/styles/variables" as *;

@forward "./styles.base.module.scss";

.list {
  font-size: $font-size-sm;
}

.item {
  margin-right: $space-xs;
}

.link {
  padding: 0;
  color: $color-footer-background;
  background: $color-secondary-900;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: background-color 0.15s ease-out;

  &:hover {
    color: $color-footer-background;
    background: $color-primary;
  }
}
