@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.productImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: auto;
    margin-right: $space-xs;
    transition: all 0.5s;

    @include lg-min {
        width: 92px;
    }
}

.container {
    border: 1px solid $color-border-light;
    padding: $space-sm;

    & + & {
        margin-top: $space-sm;
    }

    .name {
        font-size: $font-size-sm;
        font-family: $heading-font;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: $space-xs;
        letter-spacing: $letter-spacing-headline;
    }

    &.simple {
        .productImg {
            border: 1px solid $color-gray-2;
        }
    }

    &.removePaddedBorder {
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: $font-size-sm;

    @include md-min {
        flex-direction: row;
        justify-content: space-between;
    }
}

.productDetails {
    display: flex;
}

.productOptions {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: $space-xxs;
    margin-top: $space-xxs;
    border-top: 1px solid $color-gray-product-item-divider;
    width: 100%;

    @include lg-min {
        padding-left: 0;
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
        width: 100%;
        max-width: 677px;
    }
}

.appliedPromotions {
    margin-bottom: 22px;
}

.options {
    display: flex;
    gap: $space-xs;
    justify-content: space-between;
}

.price {
    display: flex;
    flex-direction: column;
}

.quantitySelector.quantitySelector {
    margin-top: $space-xxxs;
    min-width: auto;
}

.priceValue {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    letter-spacing: $letter-spacing-headline;
    margin-top: $space-xxs;
}

.actions {
    display: flex;
    margin-top: $space-md;

    button {
        font-weight: $font-weight-bold;
        text-decoration: underline;
        margin-right: $space-sm;

        @include md-min {
            white-space: nowrap;
        }

        svg {
            margin-right: $space-xxs;
        }
    }
}

.productRow {
    padding: $space-sm $space-xs;

    @include lg-min {
        padding: $space-xs 113px $space-sm $space-xxs;
    }

    h2 {
        padding-left: $space-xxs;
        padding-right: $space-xxs;
    }

    .product {
        flex-direction: column;
        justify-content: flex-start;

        @include md-min {
            flex-flow: row wrap;
            justify-content: space-between;
        }

        @include lg-min {
            flex-flow: row nowrap;
        }

        .productDetails {
            padding-left: $space-xxs;
            padding-right: $space-xxs;
        }

        .productOptions {
            margin-top: $space-sm;
            padding-left: $space-xxs;
            padding-right: $space-xxs;

            @include md-max {
                margin-top: $space-xs;
            }

            @include md-min {
                padding-left: 68px + $space-xs + $space-xxs;
            }

            @include lg-min {
                max-width: 300px;
                padding-left: $space-xxs;
            }
        }
    }
}

.productColumn {
    padding-top: $space-lg;

    & ~ & {
        background-color: $color-white;
        border-top: 2px dashed $color-border;
        margin: 0 0 -1px;
        position: relative;
        top: -1px;
    }

    h2 {
        font-family: $body-font;
        font-size: $font-size-sm;
        letter-spacing: normal;
        text-transform: capitalize;
    }

    .product {
        flex-direction: column;
        justify-content: flex-start;

        @include md-min {
            flex-direction: column;
            justify-content: flex-start;
        }

        .productOptions {
            @include md-min {
                padding-top: $space-xxs;
                margin-top: $space-xxs;
                border-top: 1px solid $color-gray-product-item-divider;
                max-width: inherit;
                width: 100%;
            }
        }
    }
}

.remove {
    display: flex;
}

.alignRight {
    text-align: right;
}

.spinner {
    position: absolute;
    inset-block-end: 0;
    inset-inline-end: 0;
    margin: 0 1em;
}
